<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.editing }}</h4>
    <div class="admin-form-wrapper">
      <div v-if="applicant" class="admin-form">
        <Form @submit="submit" :validation-schema="schema" :initial-values="applicant">

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.pin }}</label>
            <div class="reg-step-1-select-container">
              <Field name="pin" type="text"
                     v-maska="'##############'"
                     v-model="applicant.pin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content">
                  <p>{{ $lang.app.id_card_des }}</p>
                  <p>{{ $lang.app.id_card_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../../assets/images/pin-2017.png" alt="">
                      <span>{{ $lang.app.id_card }}</span>
                    </div>
                    <div class="passport-image">
                      <img src="../../../assets/images/pin-2004.png" alt="">
                      <span>{{ $lang.app.passport }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="pin" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }}</label>
            <div class="reg-step-1-select-container with-letter-buttons">
              <Field name="surname" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="applicant.surname"/>
              <ExtraKyrgyzLetters @change="applicant.surname += $event"/>
            </div>
            <ErrorMessage name="surname" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }}</label>
            <div class="reg-step-1-select-container">
              <Field name="name" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="applicant.name"/>
              <ExtraKyrgyzLetters @change="applicant.name += $event"/>
            </div>
            <ErrorMessage name="name" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }}</label>
            <div class="reg-step-1-select-container">
              <Field name="middleName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="applicant.middleName"/>
              <ExtraKyrgyzLetters @change="applicant.middleName += $event"/>
            </div>
            <ErrorMessage name="middleName" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.birth_date }}</label>
            <div class="reg-step-1-select-container">
              <Field name="dateOfBirth" type="date"
                     v-model="applicant.dateOfBirth"/>
            </div>
            <ErrorMessage name="dateOfBirth" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.phone_number }}</label>
            <div class="reg-step-1-select-container">
              <Field name="phone" type="text"
                     v-model="applicant.phone"/>
            </div>
            <ErrorMessage name="phone" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.email }}</label>
            <div class="reg-step-1-select-container">
              <Field name="email" type="email"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"
                     v-model="applicant.email"/>
            </div>
            <ErrorMessage name="email" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.citizenship }}</label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="citizenship"
                     v-model="applicant.citizenship">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="citizenship in helper.citizenships" :key="citizenship.value"
                        :value="citizenship.value">{{ citizenship.label}}</option>
              </Field>
            </div>
            <ErrorMessage name="citizenship" class="recovery-label-error" />
          </div>

          <div v-if="applicant.citizenship === 'FOREIGN'" key="FOREIGN">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.country_code }}</label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="foreignCode" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="applicant.foreignCode"
                      :options="helper.countryCodes"
                      placeholder=""
                      valueProp="code"
                      label="title"
                      :searchable="true"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
              <ErrorMessage name="foreignCode" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.document_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="foreignDoc" type="text"
                       v-model="applicant.foreignDoc"/>
              </div>
              <ErrorMessage name="foreignDoc" class="recovery-label-error" />
            </div>
          </div>

          <div v-if="applicant.citizenship === 'KG'" key="KG">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_seria }}</label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="passportRange"
                       v-model="applicant.passportRange">
                  <option v-for="passportRange in helper.passportRanges" :key="passportRange"
                          :value="passportRange">{{ passportRange }}</option>
                </Field>
              </div>
              <ErrorMessage name="passportRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="passportId" type="text"
                       v-maska="'#######'"
                       v-model="applicant.passportId"/>
                <div class="tooltip-target">
                  <i class="bi-question-circle"></i>
                  <div class="tooltip-content tooltip-content-small">
                    <p>{{ $lang.app.passport_number_des }}</p>
                    <p>{{ $lang.app.passport_number_example }}</p>
                    <div class="passport-images">
                      <div class="passport-image">
                        <img src="../../../assets/images/id-2017.png" alt="">
                        <span>{{ $lang.app.id_card }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage name="passportId" class="recovery-label-error" />
            </div>
          </div>

          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.update }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import {maska} from "maska";
import ExtraKyrgyzLetters from "../../../components/ExtraKyrgyzLetters";
import * as yup from "yup";
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      applicant: null,
      schema: yup.object({
        citizenship: yup.mixed().required(),
        pin: yup.string().min(14).
          required().matches(
            /[1,2][0-3][0-9][0-1][0-9][1,2][0,9][0-9]{7}/ 
          ),        surname: yup.string().required(),
        name: yup.string().required(),
        dateOfBirth: yup.mixed().required(),
        passportRange: yup.mixed().when('citizenship', {
          is: 'KG',
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        passportId: yup.mixed().when('citizenship', {
          is: 'KG',
          then: yup.string().required().min(7),
          otherwise: yup.string().nullable(),
        }),
        foreignCode: yup.mixed().when('citizenship', {
          is: 'FOREIGN',
          then: yup.mixed().required(),
          otherwise: yup.string().nullable(),
        }),
        foreignDoc: yup.mixed().when('citizenship', {
          is: 'FOREIGN',
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
        phone: yup.string().required(),
        email: yup.string().required().email(),
      }),
      helper: {
        citizenships: [
          {
            label: this.$lang.app.citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.citizen_other,
            value: 'FOREIGN'
          }
        ],
        passportRanges: ['AN', 'ID'],
        countryCodes: [],
      },
    }
  },
  created() {
    this.setCountryCodes()
    this.fetchResource()
  },
  methods: {
    setCountryCodes() {
      this.$axios.get(`/country`).then(({data}) => {
        this.helper.countryCodes = data
      })
    },
    fetchResource() {
      this.$axios.get(`/applicant/${this.$route.params.id}`).then(({data}) => {
        this.applicant = {
          citizenship: data.citizenshipValue,
          dateOfBirth: data.dateOfBirth,
          email: data.email,
          foreignCode: data.foreignCode,
          foreignDoc: data.foreignDoc,
          middleName: data.middleName,
          name: data.firstName,
          passportId: data.passportId,
          passportRange: data.passportRange,
          phone: data.phone,
          pin: data.pin,
          surname: data.lastName,
        }
      })
    },
    submit(values, actions) {
      this.$store.commit('startLoading')
      this.$axios.post(`/applicant/edit-personal-info/${this.$route.params.id}`, this.applicant)
          .then(() => {
            this.$snotify.success(this.$lang.app.resource_updated_successfully);
            this.$router.push(`/admin/applicants/${this.$route.params.id}`)
          }).catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data.errors);
            }
          }).finally(() => {
            this.$store.commit('stopLoading');
          })
    },
  },
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>